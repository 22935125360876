import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { addPopup, updatePopup, fetchPopupDetail } from "@/api/advert";
import store from "@/store";
import "@/utils/global.js";
export default {
  name: "edit",
  data: function data() {
    var self = this;
    return {
      // 查询字段
      editForm: {
        displayorder: '0',
        name: "",
        url: "",
        img: "",
        enabled: true
      },
      id: '',
      rules: {
        name: [{
          required: true,
          message: "充电桩名称不能为空"
        }],
        url: [{
          required: true,
          message: "弹窗广告链接不能为空"
        }]
      },
      uploadOption: {
        uploadUrl: process.env.VUE_APP_URL + "/advert/wechat/popup/upload",
        uploadHeader: {
          Authorization: "bearer " + store.getters.token
        },
        uploadName: "file",
        fileList: [],
        limit: 1
      },
      //
      editStatus: "add"
    };
  },
  created: function created() {
    this.init();
  },
  methods: {
    getPopupDetail: function getPopupDetail(id) {
      var _this = this;

      fetchPopupDetail(id).then(function (res) {
        var data = res.data;

        _this.resetEditForm(data);

        var property = data["property"];
        _this.villageOptions = [];

        if (property) {
          _this.villageOptions.push(res.data.property);
        }

        _this.$nextTick(function () {
          _this.$refs["editForm"].clearValidate();
        });
      }).catch(function (error) {
        console.log(error);
      });
    },
    init: function init() {
      if (this.$route.query && this.$route.query.idArr) {
        console.log(this.$route.query && this.$route.query.idArr);
        this.editStatus = "update";
        var id = this.$route.query.id;
        var arr = this.$route.query && this.$route.query.idArr;
        this.totalPage = arr.length;
        this.idArr = arr;
        this.idIndex = arr.indexOf(String(id));
        this.searchForm = this.$route.query.searchForm;
        console.log(this.idIndex, "index ");
        this.id = id;
        this.getPopupDetail(id);
      }
    },
    resetEditForm: function resetEditForm(data) {
      this.editForm = {
        name: data.name,
        img: data.img,
        url: data.url,
        displayorder: data.displayorder,
        enabled: data.enabled == 1 ? true : false
      };
      this.uploadOption.fileList = [{
        url: data.img_path
      }];
    },
    handleAdd: function handleAdd() {
      var _this2 = this;

      console.log("添加弹窗广告");
      this.$refs["editForm"].validate(function (valid) {
        if (valid) {
          addPopup(_this2.editForm).then(function (response) {
            _this2.$message({
              type: "success",
              message: "添加弹窗广告成功"
            });

            console.log("跳转");

            _this2.$router.push("/advert/wechat/popup");
          }).catch(function (err) {
            console.log(err);

            _this2.$message({
              type: "warning",
              message: err.data["message"]
            });
          });
        }
      });
    },
    handleUpdate: function handleUpdate() {
      var _this3 = this;

      console.log("修改弹窗广告", this.editForm);
      this.$refs["editForm"].validate(function (valid) {
        if (valid) {
          updatePopup(_this3.id, _this3.editForm).then(function (response) {
            _this3.$message({
              type: "success",
              message: "修改弹窗广告成功"
            });

            console.log("跳转");

            _this3.$router.push("/advert/wechat/popup");
          }).catch(function (err) {
            console.log(err);

            _this3.$message({
              type: "warning",
              message: err.data["message"]
            });
          });
        }
      });
    },
    handleUploadSu: function handleUploadSu(res, file) {
      console.log("success upload");
      this.editForm.img = res.data.path; // todo: 是否刷新或者添加
    },
    handleError: function handleError(res, file) {
      console.log("上传失败");
      console.log(res, file);
      this.$message({
        type: "error",
        message: res
      });
    },
    goBack: function goBack() {
      this.$router.push({
        name: "wechatPopupList"
      });
    }
  }
};